// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-certifications-and-accreditations-js": () => import("./../../../src/pages/about-us/certifications-and-accreditations.js" /* webpackChunkName: "component---src-pages-about-us-certifications-and-accreditations-js" */),
  "component---src-pages-about-us-corporate-responsibility-js": () => import("./../../../src/pages/about-us/corporate-responsibility.js" /* webpackChunkName: "component---src-pages-about-us-corporate-responsibility-js" */),
  "component---src-pages-about-us-history-js": () => import("./../../../src/pages/about-us/history.js" /* webpackChunkName: "component---src-pages-about-us-history-js" */),
  "component---src-pages-about-us-vision-and-principles-js": () => import("./../../../src/pages/about-us/vision-and-principles.js" /* webpackChunkName: "component---src-pages-about-us-vision-and-principles-js" */),
  "component---src-pages-business-solutions-delivery-solutions-js": () => import("./../../../src/pages/business-solutions/delivery-solutions.js" /* webpackChunkName: "component---src-pages-business-solutions-delivery-solutions-js" */),
  "component---src-pages-business-solutions-event-logistics-js": () => import("./../../../src/pages/business-solutions/event-logistics.js" /* webpackChunkName: "component---src-pages-business-solutions-event-logistics-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plan-moving-js": () => import("./../../../src/pages/plan-moving.js" /* webpackChunkName: "component---src-pages-plan-moving-js" */),
  "component---src-pages-services-commercial-moving-js": () => import("./../../../src/pages/services/commercial-moving.js" /* webpackChunkName: "component---src-pages-services-commercial-moving-js" */),
  "component---src-pages-services-local-removals-js": () => import("./../../../src/pages/services/local-removals.js" /* webpackChunkName: "component---src-pages-services-local-removals-js" */),
  "component---src-pages-services-packing-supplies-js": () => import("./../../../src/pages/services/packing-supplies.js" /* webpackChunkName: "component---src-pages-services-packing-supplies-js" */),
  "component---src-pages-services-residential-moving-js": () => import("./../../../src/pages/services/residential-moving.js" /* webpackChunkName: "component---src-pages-services-residential-moving-js" */),
  "component---src-pages-services-storage-solutions-js": () => import("./../../../src/pages/services/storage-solutions.js" /* webpackChunkName: "component---src-pages-services-storage-solutions-js" */)
}

